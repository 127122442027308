const planes = [
    // {
    //     plan: 'Basico',
    //     limiteProducto: 20,
    //     limiteCategoria: 3,
    //     limiteBanner: 1,
    //     limiteCodigos: 2,
    // },
    // {
    //     plan: 'Medio',
    //     limiteProducto: 40,
    //     limiteCategoria: 10,
    //     limiteBanner: 3,
    //     limiteCodigos: 5,
    // },
    {
        plan: 'Pro',
        limiteProducto: Infinity,
        limiteCategoria: Infinity,
        limiteBanner: Infinity,
        limiteCodigos: Infinity,
    }
];

export default planes;
